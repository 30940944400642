
























import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      storeDetail: (st: any) => st.aPPrepaymentStore.detail,
    }),
  },
})
export default class ModalStatusPrepayment extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean;
  @Prop({ required: true, type: String }) type!: "paid" | "used";

  // infer vuex state type
  storeDetail!: InvoiceApPrepaymentData;

  columnsUsed = [
    {
      title: this.$t("lbl_invoice_payment_date").toString(),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_payment_number").toString(),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_prepayment_used_amout").toString(),
      dataIndex: "appliedAmount",
      key: "appliedAmount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  columnsPaid = [
    {
      title: this.$t("lbl_ap_payment_date").toString(),
      dataIndex: "receiptDate",
      key: "arReceiptDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_ap_payment_number").toString(),
      dataIndex: "receiptNumber",
      key: "arReceiptNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_ap_payment_amount").toString(),
      dataIndex: "receiptAmount",
      key: "amount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  get columns() {
    return this.type === "paid" ? this.columnsPaid : this.columnsUsed;
  }

  get dataSource() {
    return this.type === "paid"
      ? this.storeDetail.paymentDetails
      : this.storeDetail.invoiceAPDetails;
  }

  get title(): string {
    return this.type === "paid"
      ? this.$t("lbl_prepayment_paid").toString()
      : this.$t("lbl_prepayment_used").toString();
  }

  closeModal(): void {
    this.$emit("close");
  }
}
