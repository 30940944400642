var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    { attrs: { visible: _vm.visible, width: "50%" } },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("a-table", {
        attrs: {
          dataSource: _vm.dataSource,
          columns: _vm.columns,
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          size: "small",
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          }
        ])
      }),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.closeModal } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }